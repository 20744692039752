





















import {Component, Vue} from 'vue-property-decorator';
import {AuthBackendAPI} from '@/logic/authentication/AuthBackendAPI';

@Component({
    components: {}
})
export default class AdminLinks extends Vue {

    getAuthenticationLink(): string {
        return "https://auth.domination-gaming.com/oauth/discord?redirect_uri=" + encodeURIComponent(window.location.href);
    }

    isAdmin(): boolean {
        return AuthBackendAPI.isAdmin(this.$store.state.currentUserState);
    }
}
